import React from 'react';
import { useMediaQuery } from 'react-responsive';
import OpenlendModal from 'components/OpenlendModal';

const ImageModal = ({ modal, docsImg, toggle }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 680px)' });
  return (
    <OpenlendModal modal={modal} toggle={toggle} className="modal-image">
      <img
        src={docsImg}
        alt=""
        width={!isMobile ? 600 : null}
        height={!isMobile ? 380 : 250}
        className={`object-fit-contain ${isMobile ? 'w-100' : ''}`}
      />
    </OpenlendModal>
  );
};

export default ImageModal;
