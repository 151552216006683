import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import Avatar from 'components/Avatar';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { APPROVE_USER_BY_ADMIN } from '../Mutation';
import { toast } from 'react-toastify';
import {
  BROKER_ROLE_KEY,
  FINAL_APPROVAL_KEY,
  PB_ROLE_KEY,
  PENDING_KEY,
  REJECTED_KEY
} from 'constant';
import { getApproveObj } from 'pages/Profile/Helper';
import { approveAll } from './Helper';

const FinalApproval = () => {
  const { id, title } = useParams();
  const navigate = useNavigate();
  const { userPersonalDetails, profileKey } = useOutletContext();

  const [approveSection, { loading }] = useMutation(APPROVE_USER_BY_ADMIN);

  const { firstName, lastName, profilePicture, phoneNumber, finalApprovalComment } =
    userPersonalDetails;

  const checkIfAnyReject = () => {
    let value = true;
    if (userPersonalDetails.approveSections) {
      for (let key in userPersonalDetails.approveSections) {
        if (userPersonalDetails.approveSections[key] === false && key !== FINAL_APPROVAL_KEY) {
          value = false;
        }
      }
      return value;
    } else {
      return true;
    }
  };

  const onSubmit = async (data, action) => {
    const { setFieldError } = action;
    const approveValue = data?.approveValue === 'true';
    const { finalApprovalComment } = data;
    if (!approveValue && !finalApprovalComment) {
      return setFieldError('finalApprovalComment', 'Please add comment');
    }
    try {
      const approveSections = approveValue
        ? approveAll(profileKey)
        : getApproveObj(
            userPersonalDetails.approveSections,
            profileKey,
            FINAL_APPROVAL_KEY,
            approveValue
          );
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: approveSections,
          finalApprovalComment,
          status: approveValue ? 'approved' : 'rejected',
          type: 'final',
          formSection: FINAL_APPROVAL_KEY
        }
      });
      toast.success(result.approveUserBySuperAdmin.message);
      return approveValue
        ? navigate(`/approvals/${title === BROKER_ROLE_KEY ? PB_ROLE_KEY : title}`)
        : navigate(`/approvals/rejected`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="mb-3">
      <Card>
        <CardBody>
          <h3 className="mb-3">Final Approval</h3>
          <div className="bg-light p-4 rounded mb-3">
            <div className="d-flex align-items-center">
              <Avatar image={profilePicture} fname={firstName} lname={lastName} size={60} />
              <div className="ms-3">
                <span>{`${firstName} ${lastName}`}</span>
                <br />
                <span className="text-muted">{phoneNumber || ''}</span>
              </div>
            </div>
          </div>
          <Formik
            initialValues={{ finalApprovalComment: finalApprovalComment || '' }}
            enableReinitialize={true}
            validationSchema={Yup.object({
              finalApprovalComment: Yup.string().nullable().trim()
            })}
            onSubmit={onSubmit}>
            {({ handleSubmit, setFieldValue }) => (
              <form
                onSubmit={(e) => {
                  const submitter = e.nativeEvent?.submitter;
                  if (submitter && submitter.name && submitter.value) {
                    setFieldValue(submitter.name, submitter.value);
                  }
                  handleSubmit(e);
                }}>
                <Field
                  size="lg"
                  name="finalApprovalComment"
                  label="Additional Comments"
                  component={FormikInputField}
                  type="textarea"
                  rows="6"
                  textAreaheight="140"
                />
                <div className="mt-3 d-flex justify-content-end">
                  {userPersonalDetails &&
                    (userPersonalDetails.approvedStatus === PENDING_KEY ||
                      userPersonalDetails.approvedStatus === REJECTED_KEY) && (
                      <>
                        <Button
                          color="danger"
                          className="ms-3"
                          disabled={loading}
                          {...{
                            type: 'submit',
                            name: 'approveValue',
                            value: false
                          }}>
                          Reject
                        </Button>
                        {checkIfAnyReject() ? (
                          <Button
                            color="primary"
                            className="ms-3"
                            disabled={loading}
                            {...{
                              type: 'submit',
                              name: 'approveValue',
                              value: true
                            }}>
                            Approve
                          </Button>
                        ) : null}{' '}
                      </>
                    )}
                </div>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </div>
  );
};

export default FinalApproval;
