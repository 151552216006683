import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import { UPDATE_FILE } from '../Mutation';
import MinioFileUpload from 'components/MinioFileUpload';
import { Col, Label } from 'reactstrap';
import { getUser } from 'utils/UserDetails';
import { useLocation } from 'react-router-dom';
import { getLogoInitials } from 'utils/helper';
import closeIcon from 'assets/icons/close.svg';

const Logo = ({ optional = false, userPersonalDetails, isBusinessLogo }) => {
  const [logo, setLogo] = useState('');
  const [lmgLoading, setImageLoading] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');
  const { pathname } = useLocation();
  const user = getUser();
  const [uploadFile] = useMutation(UPDATE_FILE);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.logo && !signedUrl) {
      setLogo(values.logo);
    }
  }, [values.logo]);

  const getLogoURL = () => {
    return logo && typeof logo === 'object' ? URL.createObjectURL(logo) : logo;
  };

  const generatePresignedUrl = async (file) => {
    try {
      const { data } = await uploadFile({
        variables: {
          fileName: file.name,
          fileType: 'doc',
          userId: user.id
        }
      });
      return data.fileApiPresignedUrl;
    } catch (error) {}
  };

  const onFileChange = async (event) => {
    const { files } = event.target;

    if (!files || files.length === 0) {
      console.error('No files selected.');
      return;
    }

    const file = files[0];

    try {
      const result = await generatePresignedUrl(file);
      setSignedUrl(result.signedUrl);
      setLogo(file);
      setImageLoading(true);
      setFieldValue('logo', result.sourcePath);
    } catch (error) {
      console.log(error);
    }
  };

  const closeLogo = () => {
    setImageLoading(true);
    setFieldValue('logo', '');
  };

  const renderLogo = () => {
    return (
      <div>
        {lmgLoading ? (
          <MinioFileUpload
            signedURL={signedUrl}
            file={logo}
            onClose={() => setImageLoading(false)}
          />
        ) : (
          <div className="d-flex upload-image-container justify-content-center align-items-center flex-column">
            <span onClick={closeLogo} className="image-cross-btn">
              <img src={closeIcon} alt="closeIcon" />
            </span>
            <img
              alt="logo"
              width={180}
              height={180}
              className="circle-img mb-2 object-fit-cover"
              src={getLogoURL()}
              onLoad={() => setImageLoading(false)}
            />
            {pathname.includes('profile') ? (
              <p className="text-center">
                <label htmlFor="logo_pic" className="text-primary cursor-pointer semi-bold">
                  Upload
                </label>
              </p>
            ) : null}
            <input
              className="d-none"
              id="logo_pic"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              onChange={onFileChange}
            />
          </div>
        )}
      </div>
    );
  };

  const getName = () => {
    if (isBusinessLogo) {
      const { legalBusinessName } = values;
      return getLogoInitials(legalBusinessName);
    } else {
      if (userPersonalDetails?.firstName && userPersonalDetails?.lastName) {
        return `${userPersonalDetails?.firstName[0]}${userPersonalDetails?.lastName[0]}`;
      } else {
        return `${user.firstName[0]}${user.lastName[0]}`;
      }
    }
  };

  return values.logo ? (
    <Col md={12}>
      <div className="d-flex justify-content-between">
        <Label>Logo</Label>
        {optional && <span className="text-muted font-size-14">Optional</span>}
      </div>
      {renderLogo()}
    </Col>
  ) : (
    <Col md={12} className="text-center">
      <div className="d-flex justify-content-between">
        <Label>Logo</Label>
        {optional && <span className="text-muted font-size-14">Optional</span>}
      </div>

      {user.id == userPersonalDetails.id ? (
        <div>
          <h1 id="circle-avatar">{getName()}</h1>
          <p className="text-center">
            <label htmlFor="logoPhoto" className={`cursor-pointer semi-bold text-primary`}>
              Upload Logo
            </label>
          </p>
          <input
            className="d-none"
            id="logoPhoto"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={onFileChange}
          />
        </div>
      ) : (
        <h1 id="circle-avatar">{getName()}</h1>
      )}
    </Col>
  );
};

export default Logo;
