import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { CommonForm } from './Helper';
import Loader from 'components/Loader';
import ProfilePicture from '../ProfilePic';

const NewDataDuplicateForm = ({ approveNewData, userPersonalDetails, onApproveSection }) => {
  const { pathname } = useLocation();
  const isPathApproval = pathname.includes('approval');

  const renderFormActionButtons = (isSubmitting) => {
    if (isPathApproval) {
      return (
        <div className="mt-4 text-end">
          <Button
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}
            color="danger"
            className="me-2"
            disabled={isSubmitting}>
            Reject
          </Button>
          {isSubmitting ? (
            <Loader />
          ) : (
            <Button
              color="primary"
              {...{
                type: 'submit',
                name: 'approveValue',
                value: true
              }}>
              Approve Section
            </Button>
          )}
        </div>
      );
    }
  };

  return (
    <Formik
      initialValues={{ ...userPersonalDetails, ...approveNewData }}
      validationSchema={Yup.object().shape({})}
      enableReinitialize={true}
      onSubmit={onApproveSection}>
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <form
          onSubmit={(e) => {
            const submitter = e.nativeEvent?.submitter;
            if (submitter?.name && submitter?.value && pathname.includes('approval')) {
              setFieldValue(submitter.name, submitter.value);
            }
            handleSubmit(e);
          }}>
          <CommonForm isNewData={true} />
          <ProfilePicture userPersonalDetails={{ ...userPersonalDetails, ...approveNewData }} />
          {renderFormActionButtons(isSubmitting)}
        </form>
      )}
    </Formik>
  );
};

export default NewDataDuplicateForm;
