import React from 'react';
import { PENDING_KEY, REJECTED_KEY } from 'constant';
import ProfilePendingCard from './ProfilePendingCard';
import ProfileRejectCard from './ProfileRejectCard';

const Submission = ({ userDetails, onlyCard = false }) => {
  if (userDetails.approvedStatus === REJECTED_KEY) {
    return <ProfileRejectCard userDetails={userDetails} onlyCard={onlyCard} />;
  }

  if (userDetails.approvedStatus === PENDING_KEY) {
    return <ProfilePendingCard userPersonalDetails={userDetails} />;
  }

  return null;
};

export default Submission;
