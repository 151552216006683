import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import infoIcon from 'assets/icons/info.svg';
import { useMediaQuery } from 'react-responsive';
import { isTabBrakePoint } from 'utils/responsiveBrakePoints';

const ProfilePendingCard = ({ userPersonalDetails }) => {
  const isTab = useMediaQuery({ query: `(${isTabBrakePoint})` });
  return (
    <Container className="greeting">
      <h2 className="content-heading">Hey {userPersonalDetails.preferredName}!</h2>
      <Row>
        <Col md={isTab ? 12 : 4} lg={isTab ? 12 : 4}>
          <Card>
            <CardBody>
              <h3>Thank you for your submission.</h3>
              <p className="font-size-18 my-4 semi-bold">
                Your profile is being reviewed, we will contact you if we have any questions.
              </p>
              <p className="d-flex align-items-center font-size-18 my-4 semi-bold">
                <img
                  src={infoIcon}
                  className="me-2"
                  alt="info icon"
                  style={{ height: '20px', width: '20px' }}
                />
                Waiting on Openfund
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePendingCard;
