import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { APPROVE_USER_BY_ADMIN, UPDATE_FILE, UPLOAD_E_SIGN } from '../Mutation';
import { getUser } from 'utils/UserDetails';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  APPROVED_KEY,
  E_SIGN_KEY,
  MORTGAGE_ADMIN,
  PB_ROLE_KEY,
  PENDING_KEY,
  PRINCIPAL_BROKER,
  REJECTED_KEY
} from 'constant';
import { useMediaQuery } from 'react-responsive';
import { getApproveObj } from 'pages/Profile/Helper';
import CombineErrorMessage from 'components/CombineError';
import Loader from 'components/Loader';
import { checkIfUserFillAllForm } from 'utils/helper';
import { RoleContext } from 'context/Role';
import { filter } from 'lodash';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';

const Esign = () => {
  const [state, setState] = useState({
    loading: '',
    hasError: false
  });
  const { userPersonalDetails, profileKey } = useOutletContext();
  const { id, title } = useParams();
  const { data: rolesList, roleTitle } = useContext(RoleContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [approveSection, { loading: approveLoading }] = useMutation(APPROVE_USER_BY_ADMIN);
  const [uploadFile] = useMutation(UPDATE_FILE);
  const [uploadEsign] = useMutation(UPLOAD_E_SIGN);
  const user = getUser();
  let sign = {};
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });

  const onClear = () => {
    sign.clear();
  };

  const findRoleIdByKey = (id) => {
    const filterParamKey = filter(rolesList?.rolesList.roles, (item) => item.id === id);
    if (filterParamKey.length) {
      return filterParamKey[0].roleKey;
    }
  };

  const getSign = async () => {
    const roleKey = findRoleIdByKey(userPersonalDetails.roleId);
    const checkSign = checkIfUserFillAllForm(roleKey, userPersonalDetails);
    if (!checkSign)
      return setState((pre) => ({
        ...pre,
        hasError: {
          error: true,
          message: 'Please ensure to fill all forms'
        }
      }));

    if (sign?.isEmpty()) return setState((pre) => ({ ...pre, hasError: true }));
    try {
      setState((pre) => ({ ...pre, loading: true }));
      let Base64 = sign.getCanvas().toDataURL();
      const data = await generatePreSignedUrl();
      const file = await fetch(Base64)
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], `${user.firstName}_signature`, { type: 'image/png' });
        });
      const req = new XMLHttpRequest();
      req.open('PUT', data.signedUrl, true);
      req.onload = () => {
        onUploadEsignConfirm(data.sourcePath);
      };
      req.send(file);
    } catch (error) {
      setState((pre) => ({ ...pre, loading: false }));
      toast.error(error.message);
    }
  };

  const onUploadEsignConfirm = async (sourcePath) => {
    try {
      const { data: result } = await uploadEsign({
        variables: {
          userId: user.id,
          confirmAndSign: sourcePath,
          reviewStatus: PENDING_KEY
        }
      });
      toast.success(result?.addConfirmAndSign.message);
      setState((pre) => ({ ...pre, loading: false }));
      navigate('/dashboard');
    } catch (error) {}
  };

  const generatePreSignedUrl = async () => {
    try {
      const { data } = await uploadFile({
        variables: {
          fileName: user.firstName,
          fileType: 'doc',
          userId: user.id
        }
      });
      return data.fileApiPresignedUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const { loading, hasError } = state;

  const onApproveEsign = async (approveValue) => {
    try {
      const { data } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: getApproveObj(
            userPersonalDetails.approveSections,
            profileKey,
            E_SIGN_KEY,
            approveValue
          ),
          status: approveValue ? APPROVED_KEY : REJECTED_KEY,
          formSection: E_SIGN_KEY
        }
      });
      navigate(`/approval/${title}/${id}/final-approval`);
      toast.success(data?.approveUserBySuperAdmin.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getLoading = () => {
    return loading ? (
      <Loader size={'lg'} />
    ) : (
      <Button color="primary" onClick={getSign} disabled={loading} size={'lg'}>
        Submit For Review
      </Button>
    );
  };

  const renderSubmitActionButtons = () => {
    if (
      pathname.includes('approval') &&
      userPersonalDetails &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return (
        <>
          <Button
            color="danger"
            className="ms-3"
            disabled={approveLoading}
            onClick={() => onApproveEsign(false)}>
            Reject
          </Button>
          <Button
            color="primary"
            className="ms-3"
            disabled={approveLoading}
            onClick={() => onApproveEsign(true)}>
            Approve Section
          </Button>
        </>
      );
    }
    if (pathname.includes('complete-profile')) {
      return getLoading();
    }
  };

  const signingText = () => {
    if (roleTitle === MORTGAGE_ADMIN) {
      return `By signing below, I, ${userPersonalDetails.preferredName}, certify the information provided is accurate and current, and that I am legally authorized to represent this Mortgage Administration Company.`;
    } else if (roleTitle === PRINCIPAL_BROKER) {
      return `By signing below, I, ${userPersonalDetails.preferredName}, certify the information provided is accurate and current, and that I am legally authorized to represent this Brokerage.`;
    }

    return `By signing below, I, ${userPersonalDetails.preferredName}, certify the information provided is accurate and
    current.`;
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardBody className="p-4">
            {hasError?.message ? (
              <CombineErrorMessage>{hasError?.message}</CombineErrorMessage>
            ) : (
              hasError && <CombineErrorMessage>Please ensure to E-sign.</CombineErrorMessage>
            )}
            <h3 className="card-heading">{id ? 'Signature' : ' Confirm and sign'}</h3>
            {!id ? <Label>{signingText()}</Label> : null}
            {id ? (
              <Label>{`I, ${userPersonalDetails.preferredName}, certify the information provided is accurate and current.`}</Label>
            ) : null}

            <div style={{ background: '#F8F8F8' }} className="p-4">
              <div className="text-end">
                {!id ? (
                  <div>
                    <Button color="link" onClick={onClear} className={`${!isMobile ? 'me-5' : ''}`}>
                      Clear
                    </Button>
                  </div>
                ) : null}

                <div className="d-flex justify-content-center">
                  {id && userPersonalDetails?.confirmAndSign ? (
                    <img
                      src={userPersonalDetails?.confirmAndSign}
                      alt={``}
                      width={isMobile ? '300' : '500'}
                      height="200"
                    />
                  ) : (
                    <div style={{ height: '200px' }} className="w-100 text-center">
                      <SignatureCanvas
                        ref={(ref) => (sign = ref)}
                        onBegin={() => setState((pre) => ({ ...pre, hasError: false }))}
                        canvasProps={{
                          className: `sigCanvas h-100 ${isMobile ? 'w-100' : ''}`
                        }}
                        clearOnResize={false}
                      />
                    </div>
                  )}
                </div>
                <div className="text-center text-muted prevent-select mt-2">
                  {/* {isMobile
                ? '----------------------------------------------'
                : '---------------------------------------------------------------'} */}
                  {!id && <p>Please sign above the dotted line.</p>}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="mt-4 d-flex justify-content-end">{renderSubmitActionButtons()}</div>
      </Col>
    </Row>
  );
};

export default Esign;
