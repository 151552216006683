import React, { useState } from 'react';
import Avatar from 'components/Avatar';
import { Button, Card, CardBody } from 'reactstrap';
import { ACCOUNT_KEY, APPROVED_KEY, PENDING_KEY, REJECTED_KEY } from 'constant';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { updateInvestorApproveSection } from 'pages/Profile/Helper';
import Loader from 'components/Loader';
import RemoveModal from 'pages/Profile/components/Investor/components/Accounts/common/RemoveModal';
import { useMutation } from '@apollo/client';
import { REMOVE_INVESTOR_ACCOUNT } from 'pages/Profile/ProfileGraphql';
import { useMediaQuery } from 'react-responsive';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import { BsArrowClockwise } from 'react-icons/bs';

export const getAccountType = (id) => {
  switch (id) {
    case 4:
      return { name: 'Joint Account', accountType: 'joint' };
    case 3:
      return { name: 'Individual Account', accountType: 'individual' };
    case 2:
      return { name: 'Corporate Account', accountType: 'corporation' };
    case 1:
      return { name: 'Trust Account', accountType: 'trust' };
    default:
      break;
  }
};

const AccountsUI = ({
  data,
  accountFormEnable,
  addNewAccount,
  userPersonalDetails,
  approveSection,
  approveLoading,
  refetch
}) => {
  const { approvedStatus } = userPersonalDetails || {};
  const [state, setState] = useState({
    modal: false,
    removeData: {}
  });
  const { modal, removeData } = state;
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [removeAccount, { loading }] = useMutation(REMOVE_INVESTOR_ACCOUNT);
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });

  const onToggle = async (data) => {
    setState((pre) => ({
      modal: !pre.modal,
      removeData: data || {}
    }));
  };

  const onApproveSection = async (type) => {
    try {
      const status = type === APPROVED_KEY;
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: updateInvestorApproveSection(
            userPersonalDetails.approveSections,
            ACCOUNT_KEY,
            status
          ),
          status: type,
          formSection: ACCOUNT_KEY
        }
      });
      toast.success(result.approveUserBySuperAdmin.message);
      navigate(`/approval/investor/${id}/lending-criteria`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderApproveButton = () => {
    return approveLoading ? (
      <Loader />
    ) : (
      <div>
        <Button color="danger" type="button" onClick={() => onApproveSection(REJECTED_KEY)}>
          Reject
        </Button>
        <Button
          className="ms-2"
          color="primary"
          type="button"
          onClick={() => onApproveSection(APPROVED_KEY)}>
          Approve
        </Button>
      </div>
    );
  };

  const onRemoveAccount = async () => {
    try {
      const { data: result } = await removeAccount({
        variables: {
          userId: user.id,
          investorAccountId: removeData.id,
          trustMemberId: removeData?.trust_member ? removeData?.trust_member?.id : null,
          corporateMemberId: removeData?.corporate_member ? removeData?.corporate_member?.id : null,
          jointMemberId: removeData?.joint_member ? removeData?.joint_member?.id : null
        }
      });
      toast.success(result.removeInvestorAccount.message);
      onToggle();
      refetch();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderButtons = (account) => {
    if (pathname.includes('approval') || pathname.includes('user')) {
      return (
        <div className="">
          {!account.isApproved && approvedStatus === 'approved' && (
            <BsArrowClockwise className="account-update" />
          )}
          <span
            className="text-primary cursor-pointer font-size-13 ms-3"
            onClick={() =>
              accountFormEnable(getAccountType(account.accountTypeId).accountType, account)
            }>
            View
          </span>
        </div>
      );
    }
    return (
      <div className="d-flex mt-2">
        <span
          className="text-primary cursor-pointer font-size-13 ms-3"
          onClick={() =>
            accountFormEnable(getAccountType(account.accountTypeId).accountType, account)
          }>
          Edit
        </span>
      </div>
    );
  };

  const renderSubmitButtons = () => {
    if (
      pathname.includes('approval') &&
      userPersonalDetails &&
      ((userPersonalDetails.approveRequiredSections &&
        userPersonalDetails.approveRequiredSections.account) ||
        userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return renderApproveButton();
    }
    if (pathname.includes('complete-profile')) {
      return (
        <Button
          color="primary"
          type="button"
          onClick={() => navigate('/complete-profile/lending-criteria')}>
          Save and continue
        </Button>
      );
    }
  };

  return (
    <div>
      <RemoveModal
        isOpen={modal}
        toggle={() => onToggle()}
        onClick={onRemoveAccount}
        name={`${removeData?.accountName}`}
        loading={loading}
      />
      <Card className="mb-3">
        <CardBody className="p-4">
          <div className="d-flex justify-content-between align-items-center card-heading">
            <h3>Accounts</h3>
            {pathname.includes('complete-profile') || pathname.includes('profile') ? (
              <Button color="link" onClick={addNewAccount} className="px-0">
                Add New +
              </Button>
            ) : null}
          </div>
          {data
            ? data.map((account) => {
                return (
                  <div
                    className="bg-light p-4 rounded mt-3 d-flex justify-content-between"
                    key={account.id}>
                    <div className={`${isMobile ? 'd-block' : 'd-flex'} align-items-center`}>
                      <Avatar fname={account.accountName} size={60} />
                      <div className={`${isMobile ? 'ms-0 mt-2' : 'ms-3'}`}>
                        <span>{account.accountName}</span>
                        <br />
                        <span className="text-muted">
                          {getAccountType(account.accountTypeId).name}
                        </span>
                      </div>
                    </div>
                    {renderButtons(account)}
                  </div>
                );
              })
            : 'NA'}
        </CardBody>
      </Card>
      <div className="text-end">{renderSubmitButtons()}</div>
    </div>
  );
};
export default AccountsUI;
