import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import { lendingCriteriaInitValues, lendingCriteriaValidation } from '..';
import MaxLTVProperty, { maxProperties } from './components/MaxLTVProperty';
import MaxLTVLocation, { maximumLtvOnLocation } from './components/MaxLTVLocation';
import MortgageOption from './components/MortgageOptions';
import Underwriting from './components/Underwriting';
import { APPROVE_USER_BY_ADMIN, LENDING_CRETERIA, UPDATE_LENDING_CRITERIA } from '../Mutation';
import { useMutation, useQuery } from '@apollo/client';
import { getUser } from 'utils/UserDetails';
import { GET_LENDING_CRETERIA } from 'pages/Profile/ProfileGraphql';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { INVESTOR_KEY, LENDING_CRITERIA_KEY, PENDING_KEY, REJECTED_KEY } from 'constant';
import { updateInvestorApproveSection, updateMAApproveSection } from 'pages/Profile/Helper';
import ErrorScroll from 'components/ErrorScroll';
import Loader from 'components/Loader';
import * as Yup from 'yup';

const LendingCriteria = () => {
  const [initValues, setInitValues] = useState(lendingCriteriaInitValues);
  const { id, title } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { userPersonalDetails, profileKey } = useOutletContext();
  const [AddLendingCreteria, { loading }] = useMutation(LENDING_CRETERIA);
  const [updateLC, { loading: updateLoading }] = useMutation(UPDATE_LENDING_CRITERIA);
  const [approveSection, { loading: approveLoading }] = useMutation(APPROVE_USER_BY_ADMIN);
  const user = getUser();

  const { data: lendingCriteria } = useQuery(GET_LENDING_CRETERIA, {
    variables: {
      userId: parseInt(id || user.id)
    }
  });

  const isCompleteProfile = pathname.includes('complete-profile');
  const isProfile = pathname.includes('profile');
  const isPathApproval = pathname.includes('approval');

  useEffect(() => {
    const div = document.querySelector('.contain-height');
    div.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (lendingCriteria && lendingCriteria.getLendingCreteria) {
      const { maximumLTVOnProperty, maximumLTVOnLocation } = lendingCriteria.getLendingCreteria;
      const arry = updateMaxProertyArry(maximumLTVOnProperty);
      const ltvLocation = updateLocationLTVArry(maximumLTVOnLocation);
      let maxPropertyInitValue = arry;
      setInitValues({
        ...lendingCriteria.getLendingCreteria,
        maximumLTVOnProperty: maxPropertyInitValue,
        maximumLTVOnLocation: ltvLocation
      });
    }
  }, [lendingCriteria]);

  const updateMaxProertyArry = (arry) => {
    const filterArry = maxProperties.map((property, index) => {
      arry.map((fetchProperty) => {
        if (property.label === fetchProperty.label) {
          property = { ...property, isShow: true, value: fetchProperty.value };
        }
      });
      return property;
    });

    return filterArry;
  };

  const updateLocationLTVArry = (arry) => {
    const filterArry = maximumLtvOnLocation.map((property, index) => {
      arry.map((fetchProperty) => {
        if (property.label === fetchProperty.label) {
          property = { ...property, isShow: true, value: fetchProperty.value };
        }
      });
      return property;
    });

    return filterArry;
  };

  const getUrlAfterUpdate = () => {
    if (isCompleteProfile) {
      return navigate('/complete-profile/e-sign');
    }
    return navigate(profileKey === INVESTOR_KEY ? '/profile/mortgage-admin' : '/profile/investors');
  };

  const updateLendingCriteria = async (data) => {
    try {
      const formObj = { ...data };
      formObj.minimumRate = Number(formObj.minimumRate);
      formObj.maximumRate = Number(formObj.maximumRate);
      formObj.minimumFree = Number(formObj.minimumFree);
      formObj.maximumFree = Number(formObj.maximumFree);
      const { data: result } = await updateLC({
        variables: {
          ...data
        }
      });
      toast.success(result?.updateLendingCreteria.message);
      getUrlAfterUpdate();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onApproveByAdmin = async (data) => {
    try {
      const approveValue = data.approveValue === 'true' ? true : false;

      const approveSectionObj =
        profileKey === INVESTOR_KEY
          ? updateInvestorApproveSection(
              userPersonalDetails.approveSections,
              LENDING_CRITERIA_KEY,
              approveValue
            )
          : updateMAApproveSection(
              userPersonalDetails.approveSections,
              LENDING_CRITERIA_KEY,
              approveValue
            );
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: approveSectionObj,
          status: approveValue ? 'approved' : 'rejected',
          formSection: LENDING_CRITERIA_KEY
        }
      });
      navigate(
        profileKey === INVESTOR_KEY
          ? `/approval/${title}/${id}/mortgage-admin`
          : `/approval/${title}/${id}/e-sign`
      );
      toast.success(result.approveUserBySuperAdmin.message);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = async (values) => {
    const formObj = { ...values };
    if (pathname.includes('approval')) return await onApproveByAdmin(formObj);
    formObj.userId = user.id;
    const filter = formObj.maximumLTVOnProperty.filter((item) => item.isShow === true);
    const filterLtvLocation = formObj.maximumLTVOnLocation.filter((item) => item.isShow === true);
    formObj.maximumLTVOnProperty = filter.map((item) => ({ label: item.label, value: item.value }));
    formObj.maximumLTVOnLocation = filterLtvLocation.map((item) => ({
      label: item.label,
      value: item.value
    }));
    formObj.maximumGDS = formObj.maximumGDS.toString();
    formObj.maximumTDS = formObj.maximumTDS.toString();
    formObj.maximumCreditScore = formObj.maximumCreditScore.toString();
    formObj.documentationImportance = `${formObj.documentationImportance}`;
    formObj.maximumMortgageSize = `${formObj.maximumMortgageSize}`;
    formObj.minimumMortgageSize = `${formObj.minimumMortgageSize}`;
    formObj.minimumRate = Number(formObj.minimumRate);
    formObj.maximumRate = Number(formObj.maximumRate);
    formObj.minimumFree = Number(formObj.minimumFree);
    formObj.maximumFree = Number(formObj.maximumFree);
    if (userPersonalDetails?.isLendingCreteria) {
      return updateLendingCriteria(formObj);
    }

    try {
      const { data: result } = await AddLendingCreteria({
        variables: {
          ...formObj
        }
      });
      toast.success(result?.addLendingCreteria.message);
      navigate('/complete-profile/e-sign');
    } catch (err) {
      toast.error(err.message);
    }
  };

  const renderLoading = () => {
    // if (params?.profile) {
    //   return updateLoading ? (
    //     <Loader />
    //   ) : (
    //     <Button type="submit" color="primary" size={'lg'}>
    //       Update
    //     </Button>
    //   );
    // }
    return loading || updateLoading ? (
      <Loader size="lg" />
    ) : (
      <Button type="submit" color="primary" size={'lg'}>
        {isCompleteProfile ? 'Save and continue' : 'Update'}
      </Button>
    );
  };

  const renderFormActionButtons = () => {
    if (
      pathname.includes('approval') &&
      userPersonalDetails &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return (
        <>
          <Button
            color="danger"
            className="ms-3"
            size="lg"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}>
            Reject
          </Button>
          <Button
            color="primary"
            className="ms-3"
            size="lg"
            disabled={approveLoading}
            {...{
              type: 'submit',
              name: 'approveValue',
              value: true
            }}>
            Approve Section
          </Button>
        </>
      );
    }
    if (isCompleteProfile || isProfile) {
      return renderLoading();
    }
  };

  const renderUpdatedFieldForm = () => {
    if (isPathApproval) {
      return <Col />;
    }
    return null;
  };

  return (
    <div>
      <Row>
        <Col>
          <Formik
            enableReinitialize={true}
            initialValues={{ ...initValues }}
            onSubmit={onSubmit}
            validationSchema={
              pathname.includes('approval') || pathname.includes('user')
                ? Yup.object().shape({})
                : lendingCriteriaValidation
            }
            validateOnMount={true}>
            {({ handleSubmit, setFieldValue }) => (
              <form
                onSubmit={(e) => {
                  const submitter = e.nativeEvent?.submitter;
                  if (submitter?.name && submitter?.value && pathname.includes('approval')) {
                    setFieldValue(submitter.name, submitter.value);
                  }
                  handleSubmit(e);
                }}>
                <ErrorScroll />
                <Underwriting />
                <MortgageOption />
                <MaxLTVProperty />
                <MaxLTVLocation />
                <div className="text-end mt-4">{renderFormActionButtons()}</div>
              </form>
            )}
          </Formik>
        </Col>
        {/* {renderUpdatedFieldForm()} */}
      </Row>
    </div>
  );
};

export default LendingCriteria;
