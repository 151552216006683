import {
  PERSONAL_INFO_KEY,
  ACCOUNT_KEY,
  LENDING_CRITERIA_KEY,
  MORTGAGE_ADMIN_KEY,
  E_SIGN_KEY,
  FINAL_APPROVAL_KEY,
  BUSINESS_INFO_KEY,
  INVESTOR_KEY,
  AGENT_KEY,
  MORTGAGE_ADMIN,
  PRINCIPAL_BROKER,
  BROKERAGE_DETAILS_KEY
} from 'constant';

const getInvestorFinalApprove = () => {
  return {
    [PERSONAL_INFO_KEY]: true,
    [ACCOUNT_KEY]: true,
    [LENDING_CRITERIA_KEY]: true,
    [MORTGAGE_ADMIN_KEY]: true,
    [E_SIGN_KEY]: true,
    [FINAL_APPROVAL_KEY]: true
  };
};

const getMAFinalApprove = () => {
  return {
    [PERSONAL_INFO_KEY]: true,
    [BUSINESS_INFO_KEY]: true,
    [LENDING_CRITERIA_KEY]: true,
    [E_SIGN_KEY]: true,
    [FINAL_APPROVAL_KEY]: true
  };
};

const getPBBrokerFinalApprove = () => {
  return {
    [PERSONAL_INFO_KEY]: true,
    [BROKERAGE_DETAILS_KEY]: true,
    [E_SIGN_KEY]: true,
    [FINAL_APPROVAL_KEY]: true
  };
};

const approveAll = (type) => {
  switch (type) {
    case INVESTOR_KEY:
      return getInvestorFinalApprove();
    case MORTGAGE_ADMIN:
      return getMAFinalApprove();
    case AGENT_KEY:
      return getPBBrokerFinalApprove();
    case PRINCIPAL_BROKER:
      return getPBBrokerFinalApprove();
    default:
      break;
  }
};

export { approveAll };
