import { gql } from '@apollo/client';

const ADD_FILOGIX_INTEGRATION = gql`
  mutation addFilogixIntegration($input: FilogixIntegrationInput!) {
    addFilogixIntegration(input: $input)
  }
`;

const UPDATE_FILOGIX_INTEGRATION = gql`
  mutation updateFilogixIntegration($filogixId: Int!, $input: FilogixIntegrationInput!) {
    updateFilogixIntegration(filogixId: $filogixId, input: $input)
  }
`;

const GET_FILOGIX_INTEGRATION = gql`
  query getFilogixIntegration($assignUserId: Int!) {
    getFilogixIntegration(assignUserId: $assignUserId)
  }
`;

export { ADD_FILOGIX_INTEGRATION, UPDATE_FILOGIX_INTEGRATION, GET_FILOGIX_INTEGRATION };
