import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import FormikCheckboxField from 'components/common/Fields/FormikCheckboxField';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FormikRadioboxField from 'components/common/Fields/FormikRadioboxField';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import CombineErrorMessage from 'components/CombineError';
import { REQUIRED } from 'constant';

const styling = {
  fontWeight: 500,
  fontFamily: '"Albert Sans", sans-serif'
};

const Underwriting = () => {
  const [hasError, setHasError] = useState(false);
  const [creditScoreError, setCreditScoreError] = useState(false);
  const { values, errors, touched, setFieldValue, setFieldError } = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  useEffect(() => {
    if (values.maximumCreditScore && values.maximumCreditScore < 0) {
      setCreditScoreError(true);
      setFieldError('maximumCreditScore', 'must be a positive number');
    } else {
      setCreditScoreError(false);
    }
  }, [values.maximumCreditScore, touched.maximumCreditScore]);

  const checkError = () => {
    return (
      (errors.documentationImportance && touched.documentationImportance) ||
      (errors.maximumGDS && touched.maximumGDS) ||
      (errors.maximumCreditScore && touched.maximumCreditScore) ||
      (errors.maximumTDS && touched.maximumTDS) ||
      (errors.isFullyOpen && touched.isFullyOpen)
    );
  };

  const renderMultipleErrors = () => {
    return (
      <>
        {touched.maximumGDS && errors.maximumGDS && errors.maximumGDS !== REQUIRED ? (
          <CombineErrorMessage>{errors.maximumGDS}</CombineErrorMessage>
        ) : null}
        {touched.maximumTDS && errors.maximumTDS && errors.maximumTDS !== REQUIRED ? (
          <CombineErrorMessage>{errors.maximumTDS}</CombineErrorMessage>
        ) : null}
        {touched.maximumCreditScore &&
        errors.maximumCreditScore &&
        errors.maximumCreditScore !== REQUIRED ? (
          <CombineErrorMessage>{errors.maximumCreditScore}</CombineErrorMessage>
        ) : null}
      </>
    );
  };

  const importance = [1, 2, 3, 4, 5];
  const fullyOpen = ['Yes', 'No', 'Sometimes'];

  const onHandleKey = (event) => {
    if (event.key === '.' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const onCheckGDS = () => {
    if (values.maximumGDS === 100) {
      setFieldValue('maximumGDS', '');
      setFieldValue('maxGDSSelect', false);
    } else {
      setFieldValue('maxGDSSelect', true);
      setFieldValue('maximumGDS', 100);
    }
  };

  const onCheckTDS = () => {
    if (values.maximumTDS === 100) {
      setFieldValue('maximumTDS', '');
    } else {
      setFieldValue('maximumTDS', 100);
    }
  };

  const onCheckCreditScore = () => {
    return values.maximumCreditScore === 0
      ? setFieldValue('maximumCreditScore', '')
      : setFieldValue('maximumCreditScore', 0);
  };

  return (
    <div>
      <Card>
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          {renderMultipleErrors()}
          {/* {creditScoreError && (
            <CombineErrorMessage>Please ensure to add positive values </CombineErrorMessage>
          )} */}
          <h3 className="card-heading">Underwriting</h3>
          <div className="mb-2">
            <Label>Importance of documentation</Label>
            <div className="d-flex">
              {importance.map((item, index) => (
                <div className="me-4" key={item}>
                  <Field
                    size="lg"
                    key={item}
                    id={item}
                    name="documentationImportance"
                    label={item}
                    checked={`${values.documentationImportance}` === `${item}`}
                    component={FormikRadioboxField}
                    isError={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <Row>
            <Col md={6} lg={6} className="mb-2">
              <Field
                size="lg"
                id="maximumGDS"
                name="maximumGDS"
                type="number"
                component={FormikInputField}
                label="Maximum GDS"
                disabled={values.maximumGDS === 100}
                isError={false}
                onKeyDown={onHandleKey}
              />
              <div className="mt-2 d-flex align-item-center radio-btn">
                <Field
                  id="maxGDSSelect"
                  type="checkbox"
                  name="maxGDSSelect"
                  checked={values.maximumGDS === 100}
                  onChange={onCheckGDS}
                />
                <label htmlFor="maxGDSSelect" className={`mb-0 ps-0 font-size-14`} style={styling}>
                  No maximum
                </label>
                {/* <Field
                  id="maxGDSSelect"
                  name="maxGDSSelect"
                  type="checkbox"
                  label="No maximum"
                  checked={values.maximumGDS === 100}
                  onClick={onCheckGDS}
                  component={FormikCheckboxField}
                /> */}
              </div>
            </Col>
            <Col md={6} lg={6} className="mb-2">
              <Field
                size="lg"
                id="maximumTds"
                name="maximumTDS"
                type="number"
                component={FormikInputField}
                label="Maximum TDS"
                disabled={values.maximumTDS === 100}
                onKeyDown={onHandleKey}
                isError={false}
              />
              <div className="mt-2 radio-btn">
                <Field
                  id="maxTDSSelect"
                  type="checkbox"
                  name="maxTDSSelect"
                  checked={values.maximumTDS === 100}
                  onChange={onCheckTDS}
                />
                <label htmlFor="maxTDSSelect" className={`mb-0 ps-0 font-size-14`} style={styling}>
                  No maximum
                </label>
                {/* <Field
                  id="maxTDSSelect"
                  name="maxTDSSelect"
                  type="checkbox"
                  label="No maximum"
                  checked={values.maximumTDS === 100}
                  component={FormikCheckboxField}
                  onClick={onCheckTDS}
                /> */}
              </div>
            </Col>
            <Col md={6} lg={6} className="mb-2">
              <Field
                size="lg"
                id="maximumCreditScore"
                name="maximumCreditScore"
                type="number"
                component={FormikInputField}
                label="Minimum Credit Score"
                disabled={values.maximumCreditScore === 0}
                isError={false}
                onInput={(e) => Math.abs(e.target.value)}
                onKeyDown={onHandleKey}
              />
              <div className="mt-2 radio-btn">
                <Field
                  id="maximumCreditScoreSelect"
                  type="checkbox"
                  name="maximumCreditScoreSelect"
                  checked={values.maximumCreditScore === 0}
                  onChange={onCheckCreditScore}
                />
                <label
                  htmlFor="maximumCreditScoreSelect"
                  className={`mb-0 ps-0 font-size-14`}
                  style={styling}>
                  No minimum
                </label>
                {/* <Field
                  id="maximumCreditScoreSelect"
                  name="maximumCreditScoreSelect"
                  type="checkbox"
                  checked={values.maximumCreditScore === 0}
                  label="No minimum"
                  component={FormikCheckboxField}
                  onClick={onCheckCreditScore}
                /> */}
              </div>
            </Col>
          </Row>
          <div className="mb-2">
            <Label>Fully Open</Label>
            <div className="d-flex">
              {fullyOpen.map((item, index) => (
                <div className="me-5" key={item}>
                  <Field
                    size="lg"
                    key={item}
                    id={item}
                    name="isFullyOpen"
                    label={item}
                    component={FormikRadioboxField}
                    isError={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Underwriting;
