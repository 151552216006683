import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button } from 'reactstrap';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { filogixIntegrationValidation } from '..';
import FilogixIntegrationForm from './FilogixIntegrationForm';
import {
  ADD_FILOGIX_INTEGRATION,
  GET_FILOGIX_INTEGRATION,
  UPDATE_FILOGIX_INTEGRATION
} from './FilogixIntegrationGraphQL';

const FilogixIntegration = () => {
  const [filogixIntegrationId, setFilogixIntegrationId] = useState(null);
  const [initialValues, setInitialValues] = useState({
    clientSystem: null,
    orgId: null,
    firmCode: null,
    userId: null
  });
  const { id } = useParams();
  const [addFilogixIntegration, { loading: addFilogixLoading }] =
    useMutation(ADD_FILOGIX_INTEGRATION);
  const [updateFilogixIntegration, { loading: updateFilogixLoading }] = useMutation(
    UPDATE_FILOGIX_INTEGRATION
  );

  const { data: filogixData } = useQuery(GET_FILOGIX_INTEGRATION, {
    variables: {
      assignUserId: id ? parseInt(id) : 0
    }
  });

  useEffect(() => {
    if (filogixData && filogixData.getFilogixIntegration) {
      const { userId, clientSystem, firmCode, orgId } = filogixData.getFilogixIntegration;
      setInitialValues({ userId, clientSystem, firmCode, orgId });
      setFilogixIntegrationId(filogixData?.getFilogixIntegration?.id);
    }
  }, [filogixData]);

  const onSubmit = async (values) => {
    const input = {
      ...values,
      assignUserId: parseInt(id)
    };
    try {
      if (filogixIntegrationId) {
        const { data: result } = await updateFilogixIntegration({
          variables: {
            filogixId: filogixIntegrationId,
            input: { ...input }
          }
        });
        toast.success(result?.updateFilogixIntegration.message);
      } else {
        const { data: result } = await addFilogixIntegration({
          variables: {
            input: { ...input }
          }
        });
        if (result && result?.addFilogixIntegration) {
          setFilogixIntegrationId(result?.addFilogixIntegration?.filogix.id);
        }
        toast.success(result?.addFilogixIntegration?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderSubmitButton = () => {
    return (
      <div className="mt-4 text-end">
        <Button
          type="submit"
          color="primary"
          className="me-2"
          disabled={addFilogixLoading || updateFilogixLoading}>
          Update
        </Button>
      </div>
    );
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={Yup.object({ ...filogixIntegrationValidation })}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validateOnMount={true}>
      {({ handleSubmit, setFieldValue, errors }) => (
        <form
          onSubmit={(e) => {
            const submitter = e.nativeEvent?.submitter;
            if (submitter?.name && submitter?.value && id) {
              setFieldValue(submitter.name, submitter.value);
            }
            handleSubmit(e);
          }}>
          <FilogixIntegrationForm />
          <div className="text-end mt-4">{renderSubmitButton()}</div>
        </form>
      )}
    </Formik>
  );
};

export default FilogixIntegration;
