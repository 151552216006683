import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { find } from 'lodash';
import { Card, CardBody, Label } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import FormikInputField from 'components/common/Fields/FormikInputField';
import CombineErrorMessage from 'components/CombineError';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';

export const maxProperties = [
  { label: 'Single Family Resident', value: '', isShow: false },
  { label: 'Condominium', value: 0, isShow: false },
  { label: 'Condo Townhouse', value: 0, isShow: false },
  { label: 'Mixed Use', value: 0, isShow: false },
  { label: 'Construction', value: 0, isShow: false },
  { label: 'Well And Septic', value: 0, isShow: false },
  { label: 'Rental', value: 0, isShow: false },
  { label: 'Rooming', value: 0, isShow: false },
  { label: 'Agriculture Zoned', value: 0, isShow: false },
  { label: 'Farm', value: 0, isShow: false }
];

const MaxLTVProperty = () => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched, values.maximumLTVOnProperty]);

  const checkError = () => {
    const { maximumLTVOnProperty } = values;
    if (typeof errors.maximumLTVOnProperty === 'string' && touched.maximumLTVOnProperty) {
      return true;
    }
    if (touched.maximumLTVOnProperty) {
      const findMax = find(
        maximumLTVOnProperty,
        (item, index) =>
          item.isShow === true && touched.maximumLTVOnProperty?.[index]?.value && !item.value
      );
      return findMax ? true : false;
    }
  };

  const { maximumLTVOnProperty } = values;

  const onChangeLoaction = (index) => {
    if (!values?.maximumLTVOnProperty?.[index].isShow) {
      setFieldValue(`maximumLTVOnProperty[${index}].value`, null);
      setFieldTouched(`maximumLTVOnProperty[${index}].value`, null);
    }
  };

  const getField = (label, isShow, index) => {
    return (
      <div
        key={label}
        className={`${
          isMobile ? `d-block mb-2` : 'd-flex'
        } justify-content-between align-items-center`}
        style={{
          minHeight: '65px',
          borderBottom: `${
            index + 1 === maximumLTVOnProperty.length ? '0px' : isMobile ? '0px' : '1px'
          } solid #dedede`
        }}>
        <Label
          className={`font-size-16 d-flex align-items-center ${isMobile ? 'mb-2' : 'mb-0'}`}
          onClick={() => onChangeLoaction(index)}>
          <Field
            size="lg"
            key={label}
            name={`maximumLTVOnProperty[${index}].isShow`}
            type="checkbox"
            className="me-2"
            style={{
              height: '17px',
              width: '17px'
            }}
          />
          {label}
        </Label>
        {isShow && (
          <div className={`${isMobile ? 'me-0' : 'me-5'}`}>
            <Field
              size="lg"
              id="sasas"
              name={`maximumLTVOnProperty[${index}].value`}
              type="number"
              component={FormikInputField}
              placeholder="Capital LTV"
              grouptextpercentage="true"
              style={{
                width: isMobile ? '60px' : '',
                borderColor:
                  errors.maximumLTVOnProperty?.[index]?.value &&
                  touched.maximumLTVOnProperty?.[index]?.value
                    ? '#ea6666'
                    : '#ced4da'
              }}
              isError={false}
            />
          </div>
        )}
      </div>
    );
  };

  const checkNumberError = () => {
    if (typeof errors.maximumLTVOnProperty === 'object') {
      return 'Please ensure you enter an Capital LTV that is between 0 to 100 for any property you have selected';
    } else {
      return 'You must set a maximum LTV for at least one property type';
    }
  };

  return (
    <div className="mt-3">
      <Card>
        <CardBody className="p-4">
          {hasError || errors.maximumLTVOnProperty ? (
            <CombineErrorMessage>{checkNumberError()}</CombineErrorMessage>
          ) : null}
          <h3 className="card-heading">Maximum LTV based on property type</h3>
          <Field
            size="lg"
            Array
            name="maximumLTVOnProperty"
            render={(arrayHelpers) => (
              <div>
                {maximumLTVOnProperty?.map((item, index) => {
                  const { label, isShow } = item;
                  if (label !== 'Farm' && label !== 'Rooming') {
                    return getField(label, isShow, index);
                  } else {
                    if (
                      label === 'Rooming' &&
                      maximumLTVOnProperty.filter((o) => {
                        return o.label == 'Rental';
                      })[0].isShow
                    ) {
                      return getField(label, isShow, index);
                    }
                    if (
                      label === 'Farm' &&
                      maximumLTVOnProperty.filter((o) => {
                        return o.label == 'Agriculture Zoned';
                      })[0].isShow
                    ) {
                      return getField(label, isShow, index);
                    }
                  }
                })}
              </div>
            )}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default MaxLTVProperty;
