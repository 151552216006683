import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import OtpInput from 'react-otp-input';

const VerifyModal = ({ open, close, value, submitOtp }) => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [hasBackError, setHasBackendError] = useState(false);

  const handleChange = (num) => {
    setOtp(num);
    setError(false);
    setHasBackendError(false);
  };

  return (
    <Modal isOpen={open} toggle={() => close()} className="modal-dialog-centered">
      <ModalBody className="p-4">
        <h4 className="mb-4">Email Verification</h4>
        <p className="font-size-16 my-4">
          We sent you a six-digit code to {value}, Enter the code below to confirm your email
          address.
        </p>
        <form>
          <div className="email-verification-input">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={false}
              inputStyle={{
                width: '45px',
                height: '45px',
                border: `1px solid ${!error ? '#BCBDC2' : '#FF0000'}`,
                background: '#F8F8F8',
                outlineColor: '#BCBDC2',
                borderRadius: '3px'
              }}
              isInputNum={true}
              hasErrored={true}
              shouldAutoFocus={true}
              inputType="tel"
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <div>
            <p className="text-end mt-3 font-size-14 text-muted">
              <span>Didn't receive the Code?</span>
              <span
                // onClick={() => submitResend(true)}
                className="ps-2 py-0 text-primary fw-600 cursor-pointer">
                Resend
              </span>
            </p>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <Button type="button" outline size="lg" className="me-3" onClick={close}>
              Back
            </Button>
            <Button
              type="button"
              size="lg"
              onClick={() => submitOtp(otp, setError)}
              color={otp.length === 6 ? 'primary' : 'muted'}
              disabled={otp.length !== 6}>
              Continue
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default VerifyModal;
