import React, { useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { ErrorMessage, useFormikContext } from 'formik';
import { getUser } from 'utils/UserDetails';
import { useMutation } from '@apollo/client';
import { UPDATE_FILE } from '../Mutation';
import MinioFileUpload from 'components/MinioFileUpload';
import { useLocation } from 'react-router-dom';

const ProfilePicture = ({ optional = false, userPersonalDetails }) => {
  const [photo, setPhoto] = useState('');
  const [loading, setLoading] = useState(false);
  const [signedUrl, setSignedUrl] = useState('');

  const [generatePresignedUrl] = useMutation(UPDATE_FILE);
  const { values, setFieldValue } = useFormikContext();
  const { pathname } = useLocation();
  const isCompleteProfile = pathname.includes('complete-profile');
  const isProfile = pathname.includes('profile');

  const user = getUser();

  const onUploadProfile = async (e) => {
    const file = e.target.files[0];
    try {
      const { data } = await generatePresignedUrl({
        variables: {
          fileName: file.name,
          fileType: 'profile',
          userId: user.id
        }
      });
      setSignedUrl(data.fileApiPresignedUrl.signedUrl);
      setLoading(true);
      setFieldValue('profilePicture', data.fileApiPresignedUrl.sourcePath);
      setPhoto(file);
    } catch (error) {
      console.log(error);
    }
  };

  const renderImage = () => {
    return loading ? (
      <MinioFileUpload signedURL={signedUrl} file={photo} onClose={() => setLoading(false)} />
    ) : (
      <img
        alt="profile pic"
        width={180}
        height={180}
        className="circle-img mb-2 object-fit-cover"
        src={getProfileURL()}
      />
    );
  };

  const getProfileURL = () => {
    return photo && typeof photo === 'object'
      ? URL.createObjectURL(photo)
      : userPersonalDetails.profilePicture;
  };

  const getName = () => {
    if (userPersonalDetails?.firstName && userPersonalDetails?.lastName) {
      return `${userPersonalDetails?.firstName[0]}${userPersonalDetails?.lastName[0]}`;
    } else {
      return `${user.firstName[0]}${user.lastName[0]}`;
    }
  };

  return (
    <div>
      <Card className="mt-4">
        <CardBody className="p-4">
          <div className="d-flex justify-content-between">
            <h3 className="card-heading">Profile Picture</h3>
            {optional && <span className="text-muted font-size-14">Optional</span>}
          </div>
          <div className="text-center">
            {values.profilePicture || userPersonalDetails.profilePicture ? (
              renderImage()
            ) : (
              <h1 id="circle-avatar">{getName()}</h1>
            )}
          </div>
          {isCompleteProfile || isProfile ? (
            <p className="text-center">
              <label
                htmlFor="profilePhoto"
                className={`cursor-pointer semi-bold ${
                  loading ? 'disable-profile-btn' : 'text-primary'
                }`}>
                Upload profile picture
              </label>
            </p>
          ) : null}
          <input
            className="d-none"
            id="profilePhoto"
            type="file"
            accept="image/png, image/gif, image/jpeg"
            onChange={onUploadProfile}
          />
          <ErrorMessage
            name={'profilePicture'}
            render={(msg) => <div className="error-message">{msg}</div>}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default ProfilePicture;
