import { gql } from '@apollo/client';

const SEND_MAIL_OTP = gql`
  mutation EmailVerification($email: String!) {
    emailVerification(email: $email)
  }
`;

const VERIFY_EMAIL_OTP = gql`
  mutation verifyOtp($email: String!, $otp: Int!) {
    verifyOtp(email: $email, otp: $otp)
  }
`;

const LENDING_CRETERIA = gql`
  mutation AddLendingCreteria(
    $userId: Int!
    $documentationImportance: String
    $maximumGDS: String
    $maximumTDS: String
    $maximumCreditScore: String
    $isFullyOpen: String
    $availableTermLength: JSON
    $availableAmortization: JSON
    $maximumMortgageSize: String
    $minimumMortgageSize: String
    $minimumRate: Float
    $maximumRate: Float
    $minimumFree: Float
    $maximumFree: Float
    $maximumLTVOnProperty: JSON
    $maximumLTVOnLocation: JSON
    $lendingPositions: JSON
  ) {
    addLendingCreteria(
      userId: $userId
      documentationImportance: $documentationImportance
      maximumGDS: $maximumGDS
      maximumTDS: $maximumTDS
      maximumCreditScore: $maximumCreditScore
      isFullyOpen: $isFullyOpen
      availableTermLength: $availableTermLength
      availableAmortization: $availableAmortization
      maximumMortgageSize: $maximumMortgageSize
      minimumMortgageSize: $minimumMortgageSize
      minimumRate: $minimumRate
      maximumRate: $maximumRate
      minimumFree: $minimumFree
      maximumFree: $maximumFree
      maximumLTVOnProperty: $maximumLTVOnProperty
      maximumLTVOnLocation: $maximumLTVOnLocation
      lendingPositions: $lendingPositions
    )
  }
`;

const UPDATE_LENDING_CRITERIA = gql`
  mutation UpdateLendingCreteria(
    $userId: Int!
    $documentationImportance: String
    $maximumGDS: String
    $maximumTDS: String
    $maximumCreditScore: String
    $isFullyOpen: String
    $availableTermLength: JSON
    $availableAmortization: JSON
    $maximumMortgageSize: String
    $minimumMortgageSize: String
    $minimumRate: Float
    $maximumRate: Float
    $minimumFree: Float
    $maximumFree: Float
    $maximumLTVOnProperty: JSON
    $maximumLTVOnLocation: JSON
    $lendingPositions: JSON
  ) {
    updateLendingCreteria(
      userId: $userId
      documentationImportance: $documentationImportance
      maximumGDS: $maximumGDS
      maximumTDS: $maximumTDS
      maximumCreditScore: $maximumCreditScore
      isFullyOpen: $isFullyOpen
      availableTermLength: $availableTermLength
      availableAmortization: $availableAmortization
      maximumMortgageSize: $maximumMortgageSize
      minimumMortgageSize: $minimumMortgageSize
      minimumRate: $minimumRate
      maximumRate: $maximumRate
      minimumFree: $minimumFree
      maximumFree: $maximumFree
      maximumLTVOnProperty: $maximumLTVOnProperty
      maximumLTVOnLocation: $maximumLTVOnLocation
      lendingPositions: $lendingPositions
    )
  }
`;

const UPDATE_FILE = gql`
  mutation FileApiPresignedUrl($fileName: String!, $fileType: String!, $userId: Int!) {
    fileApiPresignedUrl(fileName: $fileName, fileType: $fileType, userId: $userId)
  }
`;

const UPLOAD_E_SIGN = gql`
  mutation AddConfirmAndSign($userId: Int!, $confirmAndSign: String!, $reviewStatus: String!) {
    addConfirmAndSign(userId: $userId, confirmAndSign: $confirmAndSign, reviewStatus: $reviewStatus)
  }
`;

const APPROVE_USER_BY_ADMIN = gql`
  mutation ApproveUserBySuperAdmin(
    $userId: Int!
    $approveSections: JSON!
    $status: String!
    $finalApprovalComment: String
    $type: String
    $formSection: String!
  ) {
    approveUserBySuperAdmin(
      userId: $userId
      approveSections: $approveSections
      status: $status
      type: $type
      formSection: $formSection
      finalApprovalComment: $finalApprovalComment
    )
  }
`;

export {
  SEND_MAIL_OTP,
  VERIFY_EMAIL_OTP,
  LENDING_CRETERIA,
  UPDATE_FILE,
  UPLOAD_E_SIGN,
  APPROVE_USER_BY_ADMIN,
  UPDATE_LENDING_CRITERIA
};
