import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import CombineErrorMessage from 'components/CombineError';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

const MortgageOption = () => {
  const [hasError, setHasError] = useState(false);
  const [negativeError, setNegativeError] = useState(false);
  const { values, errors, touched, setFieldError } = useFormikContext();
  const { pathname } = useLocation();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched]);

  useEffect(() => {
    const err = checkNegativeError();
    setNegativeError(err);
  }, [
    values.minimumMortgageSize,
    values.maximumMortgageSize,
    values.minimumRate,
    values.maximumRate,
    values.minimumFree,
    values.maximumFree
  ]);

  const checkNegativeError = () => {
    const {
      minimumMortgageSize,
      maximumMortgageSize,
      minimumRate,
      maximumRate,
      minimumFree,
      maximumFree
    } = values;
    if (minimumMortgageSize && minimumMortgageSize < 0) {
      setFieldError('minimumMortgageSize', 'required');
      return true;
    }
    if (maximumMortgageSize && maximumMortgageSize < 0) {
      setFieldError('maximumMortgageSize', 'required');
      return true;
    }
    if (minimumRate && minimumRate < 0) {
      setFieldError('minimumRate', 'required');
      return true;
    }
    if (maximumRate && maximumRate < 0) {
      setFieldError('maximumRate', 'required');
      return true;
    }
    if (minimumFree && minimumFree < 0) {
      setFieldError('minimumFree', 'required');
      return true;
    }
    if (maximumFree && maximumFree < 0) {
      setFieldError('maximumFree', 'required');
      return true;
    }

    return false;
  };

  const checkError = () => {
    return (
      (errors.availableTermLength && touched.availableTermLength) ||
      (errors.availableAmortization && touched.availableAmortization) ||
      (errors.minimumMortgageSize && touched.minimumMortgageSize) ||
      (errors.maximumMortgageSize && touched.maximumMortgageSize) ||
      (errors.minimumRate && touched.minimumRate) ||
      (errors.maximumRate && touched.maximumRate) ||
      (errors.minimumFree && touched.minimumFree) ||
      (errors.maximumFree && touched.maximumFree) ||
      (errors.lendingPositions && touched.lendingPositions)
    );
  };
  const availableTermLength = ['6 months', '1 year', '2 year', 'Over 2 years'];
  const lendingPositions = [1, 2, 3];
  const availableAmortization = [
    'Interest Only',
    '20 years',
    '25 years',
    '30 years',
    '35 years',
    '40 years'
  ];

  const onHandleKey = (event) => {
    if (event.key === '.' || event.key === 'e') {
      event.preventDefault();
    }
  };

  const checkMortgageSizeError = () => {
    if (pathname.includes('approval') || pathname.includes('user')) {
      return null;
    }
    const { minimumMortgageSize, maximumMortgageSize } = values;
    if (maximumMortgageSize < minimumMortgageSize && maximumMortgageSize && maximumMortgageSize) {
      return (
        <CombineErrorMessage>
          Min Mortgage Size cannot be larger than Max Mortgage Size.
        </CombineErrorMessage>
      );
    }
  };

  const checkMinRate = () => {
    if (pathname.includes('approval') || pathname.includes('user')) {
      return null;
    }
    const { minimumRate, maximumRate } = values;
    if (parseFloat(maximumRate) < parseFloat(minimumRate) && touched.maximumRate) {
      return <CombineErrorMessage>Min Rate cannot be larger than Max Rate.</CombineErrorMessage>;
    }
  };

  const checkMinFees = () => {
    if (pathname.includes('approval') || pathname.includes('user')) {
      return null;
    }
    const { minimumFree, maximumFree } = values;
    if (parseFloat(maximumFree) < parseFloat(minimumFree) && touched.maximumFree) {
      return <CombineErrorMessage>Min Fee cannot be larger than Max Fee.</CombineErrorMessage>;
    }
  };

  return (
    <div>
      <Card className="mt-3">
        <CardBody className="p-4">
          {hasError && (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          )}
          {checkMortgageSizeError()}
          {checkMinRate()}
          {checkMinFees()}
          <h3 className="card-heading">Mortgage Options</h3>
          <div className="input-spacing">
            <Label>Available positions for your mortgage</Label>
            <div className="d-flex align-items-center">
              {lendingPositions?.map((item) => (
                <div md={5} lg={5} className="mb-2 me-4" key={item}>
                  <label className="d-flex align-items-center mb-1">
                    <Field
                      size="lg"
                      key={item}
                      name="lendingPositions"
                      type="checkbox"
                      value={`${item}`}
                      style={{ height: '17px', width: '17px' }}
                    />
                    <span className="ms-2 font-size-14 text-muted">
                      {moment.localeData().ordinal(item)}
                    </span>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="input-spacing">
            <Label>Available term lengths for your mortgage</Label>
            <div>
              <Row>
                {availableTermLength?.map((item) => (
                  <Col md={5} lg={5} className="mb-2" key={item}>
                    <label className="d-flex align-items-center mb-1">
                      <Field
                        size="lg"
                        key={item}
                        value={item}
                        name="availableTermLength"
                        type="checkbox"
                        style={{ height: '17px', width: '17px' }}
                      />
                      <span className="ms-2 font-size-14 text-muted">{item}</span>
                    </label>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div className="input-spacing">
            <Label>Available amortization for your mortgage</Label>
            <div>
              <Row>
                {availableAmortization?.map((item) => (
                  <Col md={5} lg={5} className="mb-2" key={item}>
                    <label className="d-flex align-items-center mb-1">
                      <Field
                        size="lg"
                        key={item}
                        name="availableAmortization"
                        type="checkbox"
                        value={item}
                        style={{ height: '17px', width: '17px' }}
                      />
                      <span className="ms-2 font-size-14 text-muted">{item}</span>
                    </label>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <div>
            {negativeError && (
              <CombineErrorMessage>Please ensure to add positive values</CombineErrorMessage>
            )}
            <Label>Mortgage specifics</Label>
            <Row>
              <Col md={6} lg={6} className="mb-3">
                <Field
                  size="lg"
                  id="minimumMortgageSize"
                  name="minimumMortgageSize"
                  type="number"
                  component={FormikInputField}
                  label="Minimum Mortgage Size"
                  groupcanadacurrency="true"
                  onKeyDown={onHandleKey}
                  isError={false}
                />
              </Col>
              <Col md={6} lg={6} className="mb-3">
                <Field
                  size="lg"
                  id="maximumMortgageSize"
                  name="maximumMortgageSize"
                  type="number"
                  component={FormikInputField}
                  label="Maximum Mortgage Size"
                  groupcanadacurrency="true"
                  onKeyDown={onHandleKey}
                  isError={false}
                  // onChange={onChangeMortgageSpecifics}
                />
              </Col>
              <Col md={6} lg={6} className="mb-3">
                <Field
                  size="lg"
                  id="minimumRate"
                  name="minimumRate"
                  type="number"
                  component={FormikInputField}
                  label="Minimum Rate"
                  grouptextpercentage="true"
                  isError={false}
                />
              </Col>
              <Col md={6} lg={6} className="mb-3">
                <Field
                  size="lg"
                  id="maximumRate"
                  name="maximumRate"
                  type="number"
                  component={FormikInputField}
                  label="Maximum Rate"
                  grouptextpercentage="true"
                  isError={false}
                />
              </Col>
              <Col md={6} lg={6} className="mb-3">
                <Field
                  size="lg"
                  id="minimumFree"
                  name="minimumFree"
                  type="number"
                  component={FormikInputField}
                  label="Minimum Fee"
                  grouptextpercentage="true"
                  isError={false}
                />
              </Col>
              <Col md={6} lg={6} className="mb-3">
                <Field
                  size="lg"
                  id="maximumFree"
                  name="maximumFree"
                  type="number"
                  component={FormikInputField}
                  label="Maximum Fee"
                  grouptextpercentage="true"
                  isError={false}
                />
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MortgageOption;
