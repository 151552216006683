import React, { useContext, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Col, Row } from 'reactstrap';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { approvePersonalInfoValidation } from '..';
import {
  BROKER_ROLE_KEY,
  MA_ROLE_KEY,
  PB_ROLE_KEY,
  PENDING_KEY,
  PERSONAL_INFO_KEY,
  REJECTED_KEY
} from 'constant';
import { APPROVE_USER_BY_ADMIN } from '../Mutation';
import { toast } from 'react-toastify';
import {
  checkIfFormValueExistInData,
  getApproveObj,
  getUpdateValuesFromObj,
  personalInfoKeys
} from 'pages/Profile/Helper';
import { RoleContext } from 'context/Role';
import { ADD_PERSONAL_INFO } from 'pages/Profile/components/Investor/components/PersonalInfo/PersonalInfoGraphQL';
import { personalInfoCommonValidation } from 'pages/OnBoarding/components/common';
import { getUser } from 'utils/UserDetails';
import { GET_SECTION_UPDATED_FIELDS } from 'pages/Profile/ProfileGraphql';
import { CommonForm } from './Helper';
import NewDataDuplicateForm from './NewDataDuplicateForm';
import { isEmpty } from 'lodash';
import ProfilePicture from '../ProfilePic';

const ApprovePersonalInfo = ({ isForm, isNewData = false }) => {
  const [initValues, setInitValues] = useState({
    firstName: '',
    lastName: '',
    preferredName: '',
    phoneNumber: '',
    email: '',
    profilePicture: null
  });
  const [approveNewData, setApproveNewData] = useState({});
  const { id, title } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { roleKey } = useContext(RoleContext);
  const user = getUser();

  const isApproval = pathname.includes('approval');

  const [updatePerosnalInfo, { loading: addLoading }] = useMutation(ADD_PERSONAL_INFO);
  const [approveSection, { loading }] = useMutation(APPROVE_USER_BY_ADMIN);
  const { userPersonalDetails, profileKey } = useOutletContext();
  const { refetch } = useQuery(GET_SECTION_UPDATED_FIELDS, {
    skip: true
  });

  useEffect(() => {
    // const profileTitle = title === BROKER_ROLE_KEY || title === MA_ROLE_KEY;
    if (isApproval && userPersonalDetails?.isUnderReviewed) {
      getNewApproveData();
    }
    if (userPersonalDetails && !isForm) {
      setInitValues(userPersonalDetails);
    }
  }, [userPersonalDetails]);

  const getNewApproveData = async () => {
    try {
      const { data } = await refetch({
        userId: Number(userPersonalDetails.id),
        sectionName: PERSONAL_INFO_KEY
      });

      const formNewData = data?.getSectionUpdatedFields?.updatedFields || {};
      setApproveNewData(formNewData);
    } catch (error) {}
  };

  const renderForm = () => {
    const checkPersonalInfoKeys =
      pathname.includes('approval') &&
      checkIfFormValueExistInData(approveNewData, personalInfoKeys);

    return (
      <CommonForm
        isNewData={isNewData}
        isOldData={checkPersonalInfoKeys}
        userPersonalDetails={userPersonalDetails}
      />
    );
  };

  if (isForm) {
    return renderForm();
  }

  const onChangeRoute = () => {
    if (title === BROKER_ROLE_KEY) {
      return navigate(`/approval/${BROKER_ROLE_KEY}/${id}/brokerage-info`);
    }
    if (title === PB_ROLE_KEY) {
      return navigate(`/approval/${PB_ROLE_KEY}/${id}/brokerage-details`);
    }
    if (title === MA_ROLE_KEY) {
      return navigate(`/approval/${MA_ROLE_KEY}/${id}/business-info`);
    }
  };

  const updatePersonalInfo = async (values) => {
    const castValues = Yup.object(approvePersonalInfoValidation).cast(values);
    const allValues = getUpdateValuesFromObj(userPersonalDetails, castValues);
    if (pathname.includes('profile') && !pathname.includes('complete-profile')) {
      allValues.isUpdating = true;
      allValues.formSection = PERSONAL_INFO_KEY;
    }
    try {
      const { data: result } = await updatePerosnalInfo({
        variables: {
          updatePersonalDetailsId: user.id,
          ...allValues
        }
      });
      toast.success(result?.updatePersonalDetails.message);
      navigate('/profile/business-info');
    } catch (error) {
      console.log(error);
      toast.error(error.message);
    }
  };

  const onSubmit = async (value) => {
    const approveValue = value.approveValue === 'true' ? true : false;
    if (pathname.includes('profile') && roleKey === MA_ROLE_KEY) return updatePersonalInfo(value);
    try {
      const { data: result } = await approveSection({
        variables: {
          userId: parseInt(id),
          approveSections: getApproveObj(
            userPersonalDetails.approveSections,
            profileKey,
            PERSONAL_INFO_KEY,
            approveValue
          ),
          status: approveValue ? 'approved' : 'rejected',
          formSection: PERSONAL_INFO_KEY
        }
      });
      toast.success(result?.approveUserBySuperAdmin.message);
      onChangeRoute();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const renderSubmitButton = () => {
    if (
      pathname.includes('approval') &&
      userPersonalDetails &&
      (userPersonalDetails.approvedStatus === PENDING_KEY ||
        userPersonalDetails.approvedStatus === REJECTED_KEY)
    ) {
      return (
        <div className="mt-4 text-end">
          <Button
            {...{
              type: 'submit',
              name: 'approveValue',
              value: false
            }}
            color="danger"
            className="me-2"
            disabled={loading}>
            Reject
          </Button>
          <Button
            {...{
              type: 'submit',
              name: 'approveValue',
              value: true
            }}
            color="primary"
            disabled={loading}>
            {loading ? 'Loading...' : 'Approve Section'}
          </Button>
        </div>
      );
    }
    if (pathname.includes('profile') && roleKey === MA_ROLE_KEY) {
      return (
        <div className="text-end">
          <Button type="submit" color="primary">
            Update
          </Button>
        </div>
      );
    }
  };

  const renderApproveNewForm = () => {
    if (!isEmpty(approveNewData) && initValues.isUnderReviewed && isApproval) {
      return (
        <Col>
          <NewDataDuplicateForm
            isNewData={isNewData}
            approveNewData={approveNewData}
            userPersonalDetails={userPersonalDetails}
            onApproveSection={onSubmit}
          />
        </Col>
      );
    }
  };

  return (
    <Row>
      <Col>
        <div className="mb-3">
          <Formik
            enableReinitialize={true}
            initialValues={initValues}
            onSubmit={onSubmit}
            validationSchema={
              pathname.includes('approval')
                ? Yup.object().shape({})
                : Yup.object(personalInfoCommonValidation)
            }>
            {({ handleSubmit, setFieldValue, values }) => (
              <form
                onSubmit={(e) => {
                  const submitter = e.nativeEvent?.submitter;
                  if (submitter?.name && submitter?.value && id) {
                    setFieldValue(submitter.name, submitter.value);
                  }
                  handleSubmit(e);
                }}>
                {renderForm(values)}
                <ProfilePicture optional userPersonalDetails={userPersonalDetails} />
                <div className="text-end mt-4">{renderSubmitButton()}</div>
              </form>
            )}
          </Formik>
        </div>
      </Col>
      {renderApproveNewForm()}
    </Row>
  );
};

export default ApprovePersonalInfo;
