import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { Col, Row, Card, CardBody } from 'reactstrap';
import ErrorScroll from 'components/ErrorScroll';
import FormikInputField from 'components/common/Fields/FormikInputField';
import CombineErrorMessage from 'components/CombineError';

const FilogixIntegrationForm = () => {
  const [hasError, setHasError] = useState(false);
  const formikContext = useFormikContext();

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [formikContext?.errors, formikContext?.touched]);

  const checkError = () => {
    if (formikContext?.errors && formikContext?.touched) {
      return formikContext?.errors.clientSystem && formikContext?.touched.clientSystem;
    }
  };
  return (
    <Card>
      <CardBody>
        {hasError && (
          <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
        )}
        <h3 className="card-heading">Filogix Integration</h3>
        <div>
          <ErrorScroll />
          <Row>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                id="clientSystem"
                name="clientSystem"
                type="text"
                component={FormikInputField}
                label="Client System"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                id="orgId"
                name="orgId"
                type="text"
                component={FormikInputField}
                label="Org Id"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                id="firmCode"
                name="firmCode"
                type="text"
                component={FormikInputField}
                label="Firm Code"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                id="userId"
                name="userId"
                type="text"
                component={FormikInputField}
                label="User Id"
                isError={false}
              />
            </Col>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default FilogixIntegrationForm;
