import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { Button, Card, CardBody } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import { getUser } from 'utils/UserDetails';
import { VERIFY_EMAIL_OTP, SEND_MAIL_OTP } from './Mutation';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { RESEND_OTP } from 'pages/OnBoarding/OnBoardingGraphQL';
import Loader from 'components/Loader';
import CombineErrorMessage from 'components/CombineError';
import { RoleContext } from 'context/Role';
import { BROKER_ROLE_KEY, INVESTOR_ROLE_KEY, MA_ROLE_KEY, PB_ROLE_KEY } from 'constant';
import { UserDetailsContext } from 'context/UserDetail';

const EmailVerify = () => {
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const userDetails = useContext(UserDetailsContext);
  const [hasBackError, setHasBackendError] = useState(false);
  const [sendOTP, { loading: sendOTPLoading }] = useMutation(SEND_MAIL_OTP);
  const [verifyOTP, { loading }] = useMutation(VERIFY_EMAIL_OTP);
  const [resendOtp, { loading: resendOtpLoading }] = useMutation(RESEND_OTP);
  const { roleKey } = useContext(RoleContext);
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });
  const navigate = useNavigate();
  const user = getUser();

  useEffect(() => {
    if (!userDetails.isEmailVerified) {
      onSendEmailOTP();
    }
  }, []);

  const onSendEmailOTP = async () => {
    try {
      await sendOTP({
        variables: {
          email: user.email
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (num) => {
    setOtp(num);
    setError(false);
    setHasBackendError(false);
  };

  const onResendOtp = async () => {
    try {
      const { data } = await resendOtp({
        variables: {
          email: user.email,
          type: 'email'
        }
      });
      toast.success(data?.resendOtp?.message);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const onNextRoute = () => {
    if (roleKey === INVESTOR_ROLE_KEY) {
      return navigate('/complete-profile/personal-info');
    }
    if (roleKey === MA_ROLE_KEY) {
      return navigate('/complete-profile/business-info');
    }
    if (roleKey === BROKER_ROLE_KEY || roleKey === PB_ROLE_KEY) {
      return navigate('/complete-profile/personal-details');
    }
  };

  const submitOtp = async () => {
    if (otp.length !== 6) return setError(true);
    try {
      const { data: result } = await verifyOTP({
        variables: {
          email: user.email,
          otp: parseInt(otp)
        }
      });
      toast.success(result?.verifyOtp.message);
      onNextRoute();
    } catch (err) {
      console.log(err);
      setHasBackendError(true);
    }
  };

  return (
    <div style={{ width: isMobile ? 'auto' : 'fit-content' }}>
      <Card>
        <CardBody>
          {hasBackError && (
            <CombineErrorMessage>
              The code entered is incorrect or has expired.
              <br />
              Please try again or resend a new code.
            </CombineErrorMessage>
          )}
          <h3 className="card-heading">Email Verification</h3>
          <div className={isMobile ? 'font-size-14 my-4' : 'font-size-16 my-4'}>
            We sent you a six-digit code to {user.email}.<br /> Please enter the code below to
            confirm your email address.
          </div>
          <div className="email-verification-input">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={false}
              inputStyle={{
                width: isMobile ? '40px' : '45px',
                height: isMobile ? '40px' : '45px',
                border: `1px solid ${!error ? '#BCBDC2' : '#FF0000'}`,
                background: '#F8F8F8',
                outlineColor: '#BCBDC2',
                borderRadius: '3px'
              }}
              isInputNum={true}
              hasErrored={true}
              inputType="tel"
              renderInput={(props) => (
                <input disabled={userDetails.isEmailVerified || false} {...props} />
              )}
            />
          </div>
          {userDetails.isEmailVerified ? null : (
            <div>
              <p className="text-end mt-3 font-size-14 text-muted">
                <span>Didn't receive the Code?</span>
                <span
                  onClick={onResendOtp}
                  disabled={resendOtpLoading}
                  className="px-2 py-0 text-primary fw-600 cursor-pointer">
                  Resend
                </span>
              </p>
            </div>
          )}
        </CardBody>
      </Card>
      <div className="d-flex justify-content-end mt-4">
        {loading || sendOTPLoading ? (
          <Loader size="lg" />
        ) : (
          <Button
            color={otp.length === 6 ? 'primary' : 'muted'}
            onClick={submitOtp}
            size="lg"
            disabled={
              loading ||
              sendOTPLoading ||
              resendOtpLoading ||
              otp.length !== 6 ||
              userDetails.isEmailVerified
            }>
            {userDetails.isEmailVerified ? 'Already Verified' : 'Verify'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmailVerify;
