import React from 'react';
import {
  LENDING_CRITERIA_KEY,
  FINAL_APPROVAL_KEY,
  BROKERAGE_DETAILS_KEY,
  BUSINESS_INFO_KEY,
  ACCOUNT_KEY,
  E_SIGN_KEY,
  CUSTOM_URL_KEY,
  PERSONAL_INFO_KEY,
  INVESTOR_KEY,
  MORTGAGE_ADMIN,
  AGENT_KEY,
  PRINCIPAL_BROKER,
  MORTGAGE_ADMIN_KEY
} from 'constant';
import { MdVerified } from 'react-icons/md';
import * as Yup from 'yup';

const updatePBAgentApproveSection = (approveSection, key, value) => {
  const section = approveSection ? approveSection : {};

  return {
    [PERSONAL_INFO_KEY]:
      key === PERSONAL_INFO_KEY
        ? value
        : typeof section[PERSONAL_INFO_KEY] === 'boolean'
        ? section[PERSONAL_INFO_KEY]
        : null,
    [BROKERAGE_DETAILS_KEY]:
      key === BROKERAGE_DETAILS_KEY
        ? value
        : typeof section[BROKERAGE_DETAILS_KEY] === 'boolean'
        ? section[BROKERAGE_DETAILS_KEY]
        : null,
    [E_SIGN_KEY]:
      key === E_SIGN_KEY
        ? value
        : typeof section[E_SIGN_KEY] === 'boolean'
        ? section[E_SIGN_KEY]
        : null,
    [FINAL_APPROVAL_KEY]:
      key === FINAL_APPROVAL_KEY
        ? value
        : typeof section[FINAL_APPROVAL_KEY] === 'boolean'
        ? section[FINAL_APPROVAL_KEY]
        : null
  };
};

const updateInvestorApproveSection = (approveSection, key, value) => {
  const section = approveSection ? approveSection : {};
  return {
    [PERSONAL_INFO_KEY]:
      key === PERSONAL_INFO_KEY
        ? value
        : typeof section[PERSONAL_INFO_KEY] === 'boolean'
        ? section[PERSONAL_INFO_KEY]
        : null,
    [ACCOUNT_KEY]:
      key === ACCOUNT_KEY
        ? value
        : typeof section[ACCOUNT_KEY] === 'boolean'
        ? section[ACCOUNT_KEY]
        : null,
    [LENDING_CRITERIA_KEY]:
      key === LENDING_CRITERIA_KEY
        ? value
        : typeof section[LENDING_CRITERIA_KEY] === 'boolean'
        ? section[LENDING_CRITERIA_KEY]
        : null,
    [MORTGAGE_ADMIN_KEY]:
      key === MORTGAGE_ADMIN_KEY
        ? value
        : typeof section[MORTGAGE_ADMIN_KEY] === 'boolean'
        ? section[MORTGAGE_ADMIN_KEY]
        : null,
    [E_SIGN_KEY]:
      key === E_SIGN_KEY
        ? value
        : typeof section[E_SIGN_KEY] === 'boolean'
        ? section[E_SIGN_KEY]
        : null,
    [FINAL_APPROVAL_KEY]:
      key === FINAL_APPROVAL_KEY
        ? value
        : typeof section[FINAL_APPROVAL_KEY] === 'boolean'
        ? section[FINAL_APPROVAL_KEY]
        : null
  };
};

const updateMAApproveSection = (approveSection, key, value) => {
  const section = approveSection ? approveSection : {};
  return {
    [PERSONAL_INFO_KEY]:
      key === PERSONAL_INFO_KEY
        ? value
        : typeof section[PERSONAL_INFO_KEY] === 'boolean'
        ? section[PERSONAL_INFO_KEY]
        : null,
    [BUSINESS_INFO_KEY]:
      key === BUSINESS_INFO_KEY
        ? value
        : typeof section[BUSINESS_INFO_KEY] === 'boolean'
        ? section[BUSINESS_INFO_KEY]
        : null,
    [LENDING_CRITERIA_KEY]:
      key === LENDING_CRITERIA_KEY
        ? value
        : typeof section[LENDING_CRITERIA_KEY] === 'boolean'
        ? section[LENDING_CRITERIA_KEY]
        : null,

    [E_SIGN_KEY]:
      key === E_SIGN_KEY
        ? value
        : typeof section[E_SIGN_KEY] === 'boolean'
        ? section[E_SIGN_KEY]
        : null,
    [FINAL_APPROVAL_KEY]:
      key === FINAL_APPROVAL_KEY
        ? value
        : typeof section[FINAL_APPROVAL_KEY] === 'boolean'
        ? section[FINAL_APPROVAL_KEY]
        : null
  };
};

const getApproveObj = (sections, profileKey, KEY, approveValue) => {
  let obj = {};
  if (profileKey === INVESTOR_KEY) {
    obj = updateInvestorApproveSection(sections, KEY, approveValue);
  }
  if (profileKey === MORTGAGE_ADMIN) {
    obj = updateMAApproveSection(sections, KEY, approveValue);
  }
  if (profileKey === AGENT_KEY || profileKey === PRINCIPAL_BROKER) {
    obj = updatePBAgentApproveSection(sections, KEY, approveValue);
  }
  return obj;
};

const getRejectContents = (item, approveSections) => {
  const { key } = item;
  const sections = approveSections ? approveSections : {};
  const {
    personal_information,
    final_approval,
    e_sign,
    brokerage_details,
    account,
    lending_criteria,
    business_info,
    mortgage_admin
  } = sections;
  switch (key) {
    case PERSONAL_INFO_KEY:
      return personal_information;
    case BROKERAGE_DETAILS_KEY:
      return brokerage_details;
    case MORTGAGE_ADMIN_KEY:
      return mortgage_admin;
    case E_SIGN_KEY:
      return e_sign;
    case FINAL_APPROVAL_KEY:
      return final_approval;
    case ACCOUNT_KEY:
      return account;
    case LENDING_CRITERIA_KEY:
      return lending_criteria;
    case BUSINESS_INFO_KEY:
      return business_info;
    default:
      break;
  }
};

const getVerifiedBadge = () => {
  return (
    <h4 className="ms-2 font-size-16" style={{ color: '#413939', marginBottom: '-10px' }}>
      Verified <MdVerified color="#0099FF" size={22} />
    </h4>
  );
};

const getUnderReviewBadge = () => {
  return (
    <h4 className="ms-2 font-size-16" style={{ color: '#413939', marginBottom: '-10px' }}>
      Under Review <MdVerified color="#FDAC40" size={22} />
    </h4>
  );
};

const getUpdateValuesFromObj = (oldData, newData) => {
  const updatedFields = Object.keys(newData).reduce((acc, key) => {
    if (oldData[key] !== newData[key]) {
      acc[key] = newData[key];
    }
    return acc;
  }, {});
  return updatedFields;
};

const checkIfFormValueExistInData = (formData, keysArry) => {
  return Object.keys(formData).find((item) => keysArry?.includes(item));
};

const checkIfMemberUpdated = (members) => {
  const findUpdatedMember = members.filter((member) => !member.isApproved || member.isRemove);
  return findUpdatedMember.length > 0;
};

const getUpdatedMembers = (members, isNewDataForm) => {
  if (isNewDataForm) {
    const filteredMembers = members.filter((member) => {
      return !member.isRemove;
    });
    return filteredMembers;
  }
  return members;
};

const identyKeys = [
  'identificationType',
  'identificationNumber',
  'identificationDocs',
  'identificationBackDocs'
];
const secondIdentyKeys = [
  'secondaryIdentificationType',
  'secondaryIdentificationNumber',
  'secondaryIdentificationDocs',
  'secondaryIdentificationBackDocs'
];

const personalInfoKeys = ['firstName', 'lastName', 'preferredName', 'phoneNumber', 'email'];

export {
  updatePBAgentApproveSection,
  updateInvestorApproveSection,
  updateMAApproveSection,
  getApproveObj,
  getRejectContents,
  getVerifiedBadge,
  getUnderReviewBadge,
  getUpdateValuesFromObj,
  checkIfFormValueExistInData,
  checkIfMemberUpdated,
  getUpdatedMembers,
  identyKeys,
  secondIdentyKeys,
  personalInfoKeys
};
